import React from 'react'
import PropTypes from 'prop-types'
let jaar = new Date().getFullYear();


const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; ROWS 2007 - {jaar}. With the help of HTML5 and Gatsby.js.</p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
