import React from 'react'
import PropTypes from 'prop-types'

// import pic02 from '../images/pic02.jpg'
// import pic03 from '../images/pic03.jpg'
import angularPic from '../images/angular_960-320.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          <span className="image main"><img src={angularPic} alt="" /></span>
          <p>Het ontwerpen en maken van een website, zoals je hem in gedachten hebt, is geen eenvoudige klus. Kom je er niet uit of heb je geen inspiratie? Dan ben je bij ROWS aan het juiste adres. Wij houden ons bezig met het ontwerpen, bouwen en onderhouden van aantrekkelijke en functionele websites. We gebruiken standaard pakketten. En waar nodig passen we natuurlijk maatwerk toe. Wij besteden alle mogelijke aandacht aan jouw wensen zodat het eindproduct precies is wat je in uw gedachten had. Per slot van rekening is je website jouw internet visitekaartje.</p>
          <p>Ook voor onderhoud van bestaande websites, de registratie van domeinnamen en de webhosting kun je bij ons terecht.</p>
          {close}
        </article>

        {/*<article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>*/}
          {/*<h2 className="major">Work</h2>*/}
          {/*<span className="image main"><img src={pic02} alt="" /></span>*/}
          {/*<p>Adipiscing magna sed dolor elit. Praesent eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam erat volutpat. Praesent urna nisi, fringila lorem et vehicula lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.</p>*/}
          {/*<p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus pharetra. Pellentesque condimentum sem. In efficitur ligula tate urna. Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus amet feugiat tempus.</p>*/}
          {/*{close}*/}
        {/*</article>*/}

        {/*<article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>*/}
          {/*<h2 className="major">About</h2>*/}
          {/*<span className="image main"><img src={pic03} alt="" /></span>*/}
          {/*<p>Lorem ipsum dolor sit amet, consectetur et adipiscing elit. Praesent eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam erat volutpat. Praesent urna nisi, fringila lorem et vehicula lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices. Aliquam libero et malesuada fames ac ante ipsum primis in faucibus. Cras viverra ligula sit amet ex mollis mattis lorem ipsum dolor sit amet.</p>*/}
          {/*{close}*/}
        {/*</article>*/}

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <form method="post" action="https://formspree.io/info@rows.nl">
            <div className="field half first">
              <label htmlFor="name">Naam</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Bericht</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="verzend bericht" className="special" /></li>
              <li><input type="reset" value="herstel" /></li>
            </ul>
          </form>
          {/*<ul className="icons">*/}
            {/*<li><a href="#" className="icon fa-twitter"><span className="label">Twitter</span></a></li>*/}
            {/*<li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a></li>*/}
            {/*<li><a href="#" className="icon fa-instagram"><span className="label">Instagram</span></a></li>*/}
            {/*<li><a href="#" className="icon fa-github"><span className="label">GitHub</span></a></li>*/}
          {/*</ul>*/}
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main